<template>
    <v-dialog :persistent="leaving" v-model="dialog" max-width="500">
        <template v-if="listItem === false" v-slot:activator="{ on }">
            <v-btn class="primary--text" text v-on="on"><v-icon small class="mr-1">exit_to_app</v-icon>{{ levelLeavingTitle }}</v-btn>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center primary--text">
                    <v-icon class="mr-1">exit_to_app</v-icon>
                    <span class="text-uppercase">{{ levelLeavingTitle }}</span>
                </div></v-card-title
            >
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-text class="mt-1">
                <v-alert prominent outlined type="warning" class="mb-0 mt-3">
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold mb-2">Access loss</span>
                        {{ warningText }}
                        <v-divider class="mt-4 primary" style="opacity: 0.22"></v-divider>
                        <v-checkbox color="primary" v-model="agreeBox">
                            <template v-slot:label>
                                <span class="primary--text">
                                    I understand
                                </span>
                            </template>
                        </v-checkbox>
                    </div>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="leaving" @click="dialog = false" color="primary" text>cancel</v-btn>
                <v-btn :loading="leaving" @click="leave" :disabled="agreeBox === false" color="error" text>leave</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'LeaveDialog',
    props: {
        levelLeavingTitle: String,
        hierarchicalLevel: String,
        id: String,
        listItem: { type: Boolean, default: false },
        orgDialog: { type: Boolean, default: false }
    },
    data() {
        return {
            dialog: false,
            agreeBox: false,
            leaving: false
        }
    },
    methods: {
        leave: function() {
            this.$data.leaving = true
            var apiURL
            if (this.$props.hierarchicalLevel === 'organization') {
                apiURL = `/orgs/${this.$route.params.oid}/leave_async`
            } else if (this.$props.hierarchicalLevel === 'space') {
                apiURL = `/spaces/${this.$route.params.sid}/leave`
            } else if (this.$props.hierarchicalLevel === 'instance') {
                apiURL = `/instances/${this.$route.params.iid}/leave`
            }
            this.$axios
                .delete(apiURL)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Successfully revoked your ${this.$props.hierarchicalLevel} role.`,
                        snackBarTimeout: 2000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Could not revoked your ${this.$props.hierarchicalLevel} role.`,
                        snackBarTimeout: 2000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.leaving = false
                    this.$data.dialog = false
                    if (this.$props.hierarchicalLevel === 'organization') {
                        this.$store.dispatch('fetchUserOrgs', false)
                        this.$router.push({ name: 'home-welcome' })
                    } else if (this.$props.hierarchicalLevel === 'space') {
                        this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid).finally(() => {
                            this.$router.push({ name: 'home-dashboard', params: { oid: this.$route.params.oid } })
                        })
                    } else if (this.$props.hierarchicalLevel === 'instance') {
                        this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid).finally(() => {
                            this.$router.push({ name: 'home-dashboard', params: { oid: this.$route.params.oid } })
                        })
                    }
                })
        }
    },
    computed: {
        warningText() {
            if (this.$props.hierarchicalLevel === 'instance') {
                return 'Leaving an instance will revoke your access to the instance and all its contents. To regain access to the instance, please contact a space administrator.'
            } else if (this.$props.hierarchicalLevel === 'space') {
                return 'Leaving a space will revoke your access to all instances and contents inside the space. To regain access to the space, please contact a space administrator.'
            } else if (this.$props.hierarchicalLevel === 'organization') {
                return 'Leaving an organization will revoke your access to all spaces and instances inside the organization. To regain access to the organization, please contact an organization manager.'
            }
            return ''
        }
    },
    watch: {
        orgDialog: function(nextVal, preVal) {
            if (nextVal === true && this.$data.dialog === false && this.$props.hierarchicalLevel === 'organization') {
                this.$data.dialog = true
            }
        },
        dialog: function(nextVal, preVal) {
            if (preVal === true && nextVal === false) {
                this.$emit('close', { value: true })
            }
        }
    }
}
</script>
